<template>
	<!-- 1-vadkadad-kdauqerd-f23kladx-8234kdo -->
	<div class="wrapper">
		<div class="container">
			<CRAPageTitle :pagedata="pagedata">
				<template v-slot:pagenav>
					<div class="pagenav">
						<button @click="prevPage" class="pagenavbtn">
							<i class="mdi mdi-chevron-left"></i>
						</button>
						<select v-model="pageId" class="pagenavselect">
							<option v-for="page in store.dashPageStructure" :key="page.pageId" :value="page.pageId">{{page.title}}</option>
						</select>
						<button @click="nextPage" class="pagenavbtn">
							<i class="mdi mdi-chevron-right"></i>
						</button>
					</div>
				</template>
				<template v-slot:rightside>
					<div class="demo-select">
						<select v-model="store.demoId" class="select-styled demo-select">
							<option value="0">All People 10+</option>
							<option v-for="demo in demos" :key="demo.id" :value="demo.id">{{demo.label}}</option>
						</select>
					</div>
				</template>
			</CRAPageTitle>
			<p class="responsive-title" v-if="pagedata && pagedata.title">{{pagedata.title}}</p>
			<p class="responsive-title-sub" v-if="pagedata && pagedata.subtitle">{{pagedata.subtitle}}</p>
			<div class="">
				<p class="sample-size-text">Sample size: <span v-if="pagedata && pagedata.sampleSizeDemo">{{pagedata.sampleSizeDemo}}</span></p>
			</div>
		</div>
		<div class="container" style="margin-top: 30px;">
			<CRAInfDialVenns v-if="pageLayout === 'CRAInfDialVenns' && pagedata" :pagedata="pagedata"></CRAInfDialVenns>
			<CRAInfDialVennsTriple v-if="pageLayout === 'CRAInfDialVennsTriple' && pagedata" :pagedata="pagedata"></CRAInfDialVennsTriple>
			<CRAInfDialBarHoriz v-if="pageLayout === 'CRAInfDialBarHoriz' && pagedata" :pagedata="pagedata"></CRAInfDialBarHoriz>
			<CRAInfDialBarVert v-if="pageLayout === 'CRAInfDialBarVert' && pagedata" :pagedata="pagedata"></CRAInfDialBarVert>
			<CRAInfDial3DPie v-if="pageLayout === 'CRAInfDial3DPie' && pagedata" :pagedata="pagedata"></CRAInfDial3DPie>
			<CRAInfDialGauges v-if="pageLayout === 'CRAInfDialGauges' && pagedata" :pagedata="pagedata"></CRAInfDialGauges>
			<CRAInfDialMultiColumn v-if="pageLayout === 'CRAInfDialMultiColumn' && pagedata" :pagedata="pagedata"></CRAInfDialMultiColumn>
			<CRAInfDialMultiPctBars v-if="pageLayout === 'CRAInfDialMultiPctBars' && pagedata" :pagedata="pagedata"></CRAInfDialMultiPctBars>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"

import CRAInfDialVenns from "@/components/layouts/CRAInfDialVenns.vue";
import CRAPageTitle from "@/components/CRAInfPageTitle.vue";
import CRAInfDialBarHoriz from "@/components/layouts/CRAInfDialBarHoriz.vue";
import CRAInfDialBarVert from "@/components/layouts/CRAInfDialBarVert.vue";
import CRAInfDial3DPie from "@/components/layouts/CRAInfDial3DPie.vue";
import CRAInfDialGauges from "@/components/layouts/CRAInfDialGauges.vue";
import CRAInfDialMultiColumn from "@/components/layouts/CRAInfDialMultiColumn.vue";
import CRAInfDialMultiPctBars from "@/components/layouts/CRAInfDialMultiPctBars.vue";
import CRAInfDialVennsTriple from "@/components/layouts/CRAInfDialVennsTriple.vue";

export default {
	name: 'D1_CRAInf',
	mixins: [globalMixin],
	components: {
		CRAInfDialVennsTriple,
		CRAInfDialMultiPctBars,
		CRAInfDialMultiColumn,
		CRAInfDialGauges,
		CRAInfDial3DPie,
		CRAInfDialBarHoriz,
		CRAPageTitle,
		CRAInfDialVenns,
		CRAInfDialBarVert,
	},
	props: {

	},
	data() {
		return {
			store: store,

			pageId: 1,
			pagedata: null,
			pageLayout: null,

			demos: [
				// {id: 6669, label: 'Radio Listener p7d'},
				// {id: 6295, label: 'Listen to AM/FM Radio p7d'},
				// {id: 6296, label: 'Listen to DAB+ Radio p7d'},
				// {id: 6300, label: 'Listen to Commercial Radio p7d'},
				// {id: 6302, label: 'Listen to Podcasting past mth'},
				// {id: 6303, label: 'Listen to Podcasting p7d'},
				// {id: 6623, label: 'Listen to Ad Supported Streaming p7d'},
				// {id: 6304, label: 'P10-24'},
				{id: 6594, label: 'P18+'},
				{id: 6857, label: 'P18-44'},
				{id: 6305, label: 'P25-54'},
				{id: 6306, label: 'P55+'},
				{id: 6386, label: 'Metro'},
				{id: 6600, label: 'Regional'},
				{id: 6603, label: 'Females'},
				{id: 6602, label: 'Males'},
				{id: 6639, label: 'Commercial Radio p7d'},
			],

		}
	},
	computed: {
		nPages() {
			return this.store.dashPageStructure.length
		},
	},
	methods: {
		prevPage() {
			if(this.pageId > 1) this.pageId--
		},
		nextPage() {
			if(this.pageId < this.nPages) this.pageId++
		},
		getData() {
			this.getDashboardData(this.pageId).then(function(ret) {
				if(ret && ret.pageResult) {
					this.pagedata = ret.pageResult
				}
				if(ret && ret.pageResult && ret.pageResult.layout) {
					this.pageLayout = ret.pageResult.layout
				}
			}.bind(this))
		},
	},
	watch: {
		pageId() {
			this.getData()
		},
		'store.demoId'() {
			this.getData()
		},
	},
	mounted() {
		this.getData()
	},
	created() {

	},
}
</script>

<style scoped>
.wrapper {
	max-width: 1200px;
	margin: 0 auto;
}
.container {
	margin: 0 30px;
}
.pagenav {
	display: flex;
	align-items: center;
}
.pagenavbtn {
	padding: 9px 15px;
	color: #FFF;
	cursor: pointer;
	border-radius: 5px;
	background-color: #6c94d4;
	border: none;
	font-size: 18px;
	transition: all 0.2s ease-in-out;
}
.pagenavbtn:hover {
	background-color: #8b77b9;
}
.pagenavselect {
	margin: 0 5px;
	color: #FFF;
	background-color: #6c94d4;
	border: none;
	padding: 11px 5px;
	border-radius: 5px;
	max-width: 400px;
}
.sample-size-text {
	font-size: 12px;
}
.responsive-title {
	font-size: 22px;
	font-weight: bold;
	margin-bottom: 10px;
}
.responsive-title-sub {
	font-size: 14px;
	margin-bottom: 10px;
}
.select-styled.demo-select {
	margin-left: 10px;
}

@media screen and (max-width: 1000px) {
	.pagenav {
		width: 100%;
		justify-content: center;
	}
	.pagenavbtn {

	}
	.pagenavselect {

	}
	.demo-select {
		margin: 0 auto;
	}
}
@media screen and (max-width: 800px) {
	.pagenavselect {
		max-width: 200px;
	}
}
</style>
